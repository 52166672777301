import { Box } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';

export enum ContentMbSize {
  Small = 5,
  Large = 7,
}

interface ContentProps {
  children: ReactNode;
  mbSize?: ContentMbSize;
}

const Content: FC<ContentProps> = (props) => {
  const { children, mbSize = ContentMbSize.Small } = props;
  return <Box mb={mbSize}>{children}</Box>;
};

export default Content;
