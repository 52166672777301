import Box from '@material-ui/core/Box';
import React, { FC } from 'react';
import { InformationModule } from '../../../components';
import { ContentDirection } from '../../../components/InformationModule/InformationModule';
import { useShopConfig } from '../../../hooks/shopConfig';
import { useEnabledCustomerExperiences } from '../../../hooks/useEnabledCustomerExperiences';
import { ABOUT_PAGES_CONTENT_WIDTH, getTradeInContentConfig } from '../aboutPageUtils';
import BuyerSellerInfoTitle from './BuyerSellerInfoTitle';

const TradeInHowItWorks: FC = () => {
  const { shopName, images, buyerSellerInfoOverrides } = useShopConfig();
  const customIcons = images?.howItWorksCustomImages;
  const { isTradeInOnly } = useEnabledCustomerExperiences();
  const tradeInContent = getTradeInContentConfig(
    shopName,
    customIcons,
    buyerSellerInfoOverrides,
    !isTradeInOnly
  );
  const title = isTradeInOnly ? 'Start Selling' : 'Trading In On Treet';

  return (
    <Box width="100%" maxWidth={ABOUT_PAGES_CONTENT_WIDTH} alignSelf="center">
      <Box display="flex" flexDirection="column" alignItems="center">
        <BuyerSellerInfoTitle>{title}</BuyerSellerInfoTitle>
        <InformationModule content={tradeInContent} direction={ContentDirection.Horizontal} />
      </Box>
    </Box>
  );
};

export default TradeInHowItWorks;
