import { isEmpty } from 'lodash';
import { Box } from '@material-ui/core';
import React, { FC, useEffect } from 'react';
import { InlineTextButton, Navbar } from '../../../components';
import { useShopConfig } from '../../../hooks/shopConfig';
import { useIsMobile } from '../../../hooks/useIsMobile';
import { AboutNavBarTab, AboutPageTab, getAboutNavbarTabs } from '../aboutPageUtils';
import css from '../AboutPage.module.css';
import { useEnabledCustomerExperiences } from '../../../hooks/useEnabledCustomerExperiences';
import { TypographyFormat } from '../../../components/TypographyWrapper/TypographyWrapper';

const ABOUT_NAVBAR_ID = 'aboutNavbar';

interface AboutNavbarProps {
  selectedTabName: string;
  onTabSelected: (tab: AboutPageTab) => void;
}

const AboutNavbar: FC<AboutNavbarProps> = (props: AboutNavbarProps) => {
  const { selectedTabName, onTabSelected } = props;

  const { enabledAboutTreetPages = [] } = useShopConfig();
  const { allowTradeIn, allowBuy, isTradeInOnly, allowSell } = useEnabledCustomerExperiences();
  const isMobile = useIsMobile();
  const shouldUseAboutTreetPages = !isEmpty(enabledAboutTreetPages);

  const tabs = getAboutNavbarTabs(
    allowBuy,
    allowSell,
    allowTradeIn,
    isTradeInOnly,
    shouldUseAboutTreetPages
  );

  const tabId = (tab: AboutNavBarTab) => `aboutNavBar-${tab.text}`;

  // Scrolls the selected element to the center of navbar
  const scrollNavBarToSelectedTab = () => {
    const selectedTab = tabs.find((tab) => tab.tab === selectedTabName);
    if (!selectedTab) {
      return;
    }
    const selectedTabId = tabId(selectedTab);

    const aboutNavBarEl = document.getElementById(ABOUT_NAVBAR_ID);
    const selectedTabEl = document.getElementById(selectedTabId);

    if (aboutNavBarEl && selectedTabEl) {
      const selectedTabBoundingRect = selectedTabEl.getBoundingClientRect();
      const aboutNavBarBoundingRect = aboutNavBarEl.getBoundingClientRect();

      const scrollLeftPosition =
        selectedTabEl.offsetLeft -
        aboutNavBarBoundingRect.left -
        aboutNavBarBoundingRect.width / 2 +
        selectedTabBoundingRect.width / 2;

      aboutNavBarEl.scrollTo({
        left: scrollLeftPosition,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    scrollNavBarToSelectedTab();
    // Also needs to depend on tabs because the treetShopName loads in with a bit of delay and
    // it calculates the scroll without the first tab
  }, [selectedTabName, tabs]);

  return (
    <>
      <Navbar
        wrapperBoxOverrides={{
          justifyContent: isMobile && !isTradeInOnly ? 'start' : 'center',
          component: 'ul',
          style: { marginBlock: 'unset' },
        }}
        id={ABOUT_NAVBAR_ID}
      >
        {tabs.map((tab) => (
          <Box m={1} key={tab.text} id={tabId(tab)} component="li">
            <InlineTextButton
              rootClassName={css.tabLinkButton}
              onClick={() => onTabSelected(tab.tab)}
              typographyVariant="subtitle1"
              typographyOverrides={{ style: { whiteSpace: 'nowrap', margin: 0 } }}
              typographyFormat={
                tab.tab === selectedTabName
                  ? TypographyFormat.Underlined
                  : TypographyFormat.HoverUnderlined
              }
            >
              {tab.text}
            </InlineTextButton>
          </Box>
        ))}
      </Navbar>
    </>
  );
};
export default AboutNavbar;
