import React, { FC, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { capitalize, isEmpty } from 'lodash';
import { NamedRedirect, Page } from '../../components';
import { useShopConfig } from '../../hooks/shopConfig';
import { AboutPageTab, getAboutNavbarTabs } from './aboutPageUtils';
import AboutPagesWrapper from './components/AboutPagesWrapper';
import AboutTreetTab from './components/AboutTreetTab';
import BuyerInfoTab from './components/BuyerInfoTab';
import InfoTab from './components/InfoTab';
import TreetProtectionTab from './components/TreetProtectionTab';
import SellerInfoTab from './components/SellerInfoTab';
import SupportTab from './components/SupportTab';
import { useIsMobile } from '../../hooks/useIsMobile';
import TradeInInfoTab from './components/TradeInInfoTab';
import { useEnabledCustomerExperiences } from '../../hooks/useEnabledCustomerExperiences';
import { createResourceLocatorString } from '../../util/routes';
import { NO_SCROLL } from '../../Routes';
import { useRouteConfiguration } from '../../hooks/useRouteConfiguration';

const AboutPage: FC = () => {
  const { treetShopName, images, enabledAboutTreetPages = [] } = useShopConfig();
  const isMobile = useIsMobile();

  const { allowTradeIn, allowBuy, isTradeInOnly, allowSell } = useEnabledCustomerExperiences();
  const shouldUseAboutTreetPages = !isEmpty(enabledAboutTreetPages);

  const schemaTitle = treetShopName;
  const availableTabs = getAboutNavbarTabs(
    allowBuy,
    allowSell,
    allowTradeIn,
    isTradeInOnly,
    shouldUseAboutTreetPages
  ).map(({ tab }) => tab);
  const { tab: tabInParam } = useParams() as { tab?: AboutPageTab };
  const routes = useRouteConfiguration();
  const history = useHistory();

  const [selectedTab, setSelectedTab] = useState<AboutPageTab>(tabInParam || AboutPageTab.Info);
  useEffect(() => {
    setSelectedTab(tabInParam || AboutPageTab.Info);
  }, [tabInParam]);

  if (!tabInParam || !availableTabs.includes(tabInParam)) {
    return <NamedRedirect name="AboutPage" params={{ tab: AboutPageTab.Info }} />;
  }

  const aboutImageDefaultUrl = images?.aboutImage?.url;
  const aboutImageMobileUrl = images?.aboutImageMobile?.url;
  const aboutImageUrl = (isMobile && aboutImageMobileUrl) || aboutImageDefaultUrl;

  const renderTab = (tab: AboutPageTab) => {
    switch (tab) {
      case AboutPageTab.Info:
        return <InfoTab />;
      case AboutPageTab.Buyer:
        return <BuyerInfoTab />;
      case AboutPageTab.Seller:
        return <SellerInfoTab />;
      case AboutPageTab.TradeIn:
        return <TradeInInfoTab />;
      case AboutPageTab.Support:
        return <SupportTab />;
      case AboutPageTab.AboutTreet:
        return <AboutTreetTab />;
      case AboutPageTab.Protection:
        return <TreetProtectionTab />;
      default:
        return null;
    }
  };

  return (
    <Page
      contentType="website"
      title={`About - ${capitalize(selectedTab)} | ${treetShopName}`}
      // TODO (SY|TREET-1008): Change sharing images to be a different image
      facebookImages={[{ url: aboutImageUrl, width: 1200, height: 630 }]}
      twitterImages={[{ url: aboutImageUrl, width: 600, height: 314 }]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        name: schemaTitle,
        image: [aboutImageUrl],
      }}
    >
      <AboutPagesWrapper
        onTabSelected={(tab: AboutPageTab) => {
          // Change url without rerendering page
          const to = createResourceLocatorString(
            'AboutPage',
            routes,
            {
              tab,
            },
            { [NO_SCROLL]: true }
          );
          history.replace(to);
          setSelectedTab(tab);
        }}
        selectedTabName={selectedTab}
        allowFullBleed={selectedTab === AboutPageTab.Info}
      >
        {renderTab(selectedTab)}
      </AboutPagesWrapper>
    </Page>
  );
};
export default AboutPage;
