import { Box } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, NamedLink, TradeInFAQ } from '../../../components';
import { useStyleType } from '../../../hooks/useStyleType';
import { fetchSoldListings } from '../AboutPage.duck';
import TradeInHowItWorks from './TradeInHowItWorks';
import BuyerSellerInfoTitle from './BuyerSellerInfoTitle';
import Content, { ContentMbSize } from './Content';
import css from '../AboutPage.module.css';
import { useEnabledCustomerExperiences } from '../../../hooks/useEnabledCustomerExperiences';

const TradeInInformationModule: FC = () => (
  <Content mbSize={ContentMbSize.Large}>
    <TradeInHowItWorks />
  </Content>
);

const TradeInFAQs: FC = () => {
  const { isTradeInOnly } = useEnabledCustomerExperiences();

  return (
    <>
      <BuyerSellerInfoTitle>{isTradeInOnly ? 'Notes' : 'Trade-In FAQs'}</BuyerSellerInfoTitle>
      <Content mbSize={ContentMbSize.Large}>
        <TradeInFAQ />
      </Content>
    </>
  );
};

const TradeInInfoTab: FC = () => {
  const { isTradeInOnly, allowTradeInAndMarketplace } = useEnabledCustomerExperiences();
  const { isCondensedStyle } = useStyleType();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSoldListings());
  }, []);

  return (
    <>
      <TradeInInformationModule />
      <TradeInFAQs />
      <Box display={{ md: 'flex' }} justifyContent="center" mb={5}>
        <NamedLink
          name={allowTradeInAndMarketplace ? 'NewListingPage' : 'ManageTradeInsPage'}
          style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center' }}
        >
          <Button className={classNames({ [css.mobileButtonCondensed]: isCondensedStyle })}>
            {isTradeInOnly ? 'Start Selling' : 'Trade In'}
          </Button>
        </NamedLink>
      </Box>
    </>
  );
};

export default TradeInInfoTab;
