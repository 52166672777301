import React, { FC, useState } from 'react';
import { Box } from '@material-ui/core';
import { BuilderSection, HeroActions, SectionAbout } from '../../../components';
import { useShopConfig, useShopConfigV2 } from '../../../hooks/shopConfig';
import { AboutPageInfoTabSection } from '../../../types/shopConfig/shopConfigV2';
import { ABOUT_PAGES_CONTENT_WIDTH } from '../aboutPageUtils';
import HowItWorksV2 from './HowItWorksV2';
import TradeInHowItWorks from './TradeInHowItWorks';
import { BuilderSections } from '../../../util/builder';
import { BuilderAboutPageInfoTabResponseData } from '../../../types/builder/builder';

const AboutSection: FC = () => {
  const { css: brandCss } = useShopConfigV2();

  return (
    <Box
      display="flex"
      justifyContent="center"
      bgcolor={brandCss?.tintColor}
      width="100%"
      px={{ xs: 3, md: 5, lg: 6 }}
      py={5}
    >
      <SectionAbout actionEl={<HeroActions isOnImage={false} />} />
    </Box>
  );
};

const HowItWorksSection: FC = () => (
  <Box my={5} width="100%" maxWidth={ABOUT_PAGES_CONTENT_WIDTH} alignSelf="center">
    <HowItWorksV2 />
  </Box>
);

const InfoTab: FC = () => {
  const { css: brandCss } = useShopConfigV2();
  const { builderConfig } = useShopConfig();

  const [builderData, setBuilderData] = useState<BuilderAboutPageInfoTabResponseData | undefined>();
  const aboutPageInfoTabSectionId =
    builderConfig?.sections?.[BuilderSections.AboutPageInfoTabContent];
  const CustomAboutSection = (
    <BuilderSection
      sectionType={BuilderSections.AboutPageInfoTabContent}
      sectionId={aboutPageInfoTabSectionId}
      fetchDataFromBuilder={(builderResponse) => setBuilderData(builderResponse)}
    />
  );
  const shouldShowHowItWorksModule =
    !aboutPageInfoTabSectionId || builderData?.shouldShowHowItWorksModule;

  const aboutPageInfoTabLayout = brandCss?.aboutPageInfoTabLayout || [];

  const sectionToComponent = {
    [AboutPageInfoTabSection.About]: aboutPageInfoTabSectionId ? (
      CustomAboutSection
    ) : (
      <AboutSection key="about" />
    ),
    [AboutPageInfoTabSection.HowItWorks]: shouldShowHowItWorksModule ? (
      <HowItWorksSection key="howItWorks" />
    ) : undefined,
    [AboutPageInfoTabSection.TradeInHowItWorks]: shouldShowHowItWorksModule ? (
      <Box display="flex" justifyContent="center" px={3} py={7} key="tradeInHowItWorks">
        <TradeInHowItWorks />
      </Box>
    ) : undefined,
  };

  return <>{aboutPageInfoTabLayout.map((section) => sectionToComponent[section])}</>;
};

export default InfoTab;
