import { Box } from '@material-ui/core';
import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, InformationModule, NamedLink, SellerFAQ } from '../../../components';
import { ContentDirection } from '../../../components/InformationModule/InformationModule';
import ListingItemsCarousel, {
  MIN_CAROUSEL_LISTINGS,
} from '../../../components/ListingItemsCarousel/ListingItemsCarousel';
import { useShopConfigV2 } from '../../../hooks/shopConfig';
import { useIsShopSideLaunched } from '../../../hooks/useIsShopSideLaunched';
import { useStyleType } from '../../../hooks/useStyleType';
import { AboutPageState, fetchSoldListings } from '../AboutPage.duck';
import { getSellerContentConfig } from '../aboutPageUtils';
import BuyerSellerInfoTitle from './BuyerSellerInfoTitle';
import Content, { ContentMbSize } from './Content';
import css from '../AboutPage.module.css';
import { trackClickListingCard, TrackListingCardParams } from '../../../util/heap';

const SellerInformationModule: FC = () => {
  const { buyerSellerInfoOverrides, cashPayoutPercentage, creditPayoutPercentage, images } =
    useShopConfigV2();
  const customIcons = images?.howItWorksCustomImages;

  const content = getSellerContentConfig(
    creditPayoutPercentage,
    cashPayoutPercentage,
    customIcons,
    buyerSellerInfoOverrides
  );

  return (
    <>
      <BuyerSellerInfoTitle>Selling on Treet</BuyerSellerInfoTitle>
      <Content mbSize={ContentMbSize.Large}>
        <InformationModule content={content} direction={ContentDirection.Horizontal} />
      </Content>
    </>
  );
};

const SellerFAQs: FC = () => (
  <>
    <BuyerSellerInfoTitle>Seller FAQs</BuyerSellerInfoTitle>
    <Content mbSize={ContentMbSize.Large}>
      <SellerFAQ />
    </Content>
  </>
);

const RecentlySoldListings: FC = () => {
  const isShopSideLaunched = useIsShopSideLaunched();
  const { soldListings } = useSelector<any>((state) => state.AboutPage) as AboutPageState;

  const trackListingCardHeapEvent = (params: TrackListingCardParams) => {
    trackClickListingCard({
      ...params,
      originOfClick: 'SoldListingsItemCarousel',
    });
  };

  if (!isShopSideLaunched || soldListings.length < MIN_CAROUSEL_LISTINGS) return null;

  return (
    <>
      <BuyerSellerInfoTitle>Just Sold</BuyerSellerInfoTitle>
      <Content mbSize={ContentMbSize.Large}>
        <ListingItemsCarousel
          trackClickListingCardHeapEvent={trackListingCardHeapEvent}
          listings={soldListings}
          referrerLocation="Seller Info Page"
        />
      </Content>
    </>
  );
};

const SellerInfoTab: FC = () => {
  const { isCondensedStyle } = useStyleType();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSoldListings());
  }, []);

  return (
    <>
      <SellerInformationModule />
      <SellerFAQs />
      <RecentlySoldListings />
      <Box display={{ md: 'flex' }} justifyContent="center" mb={5}>
        <NamedLink
          name="NewListingPage"
          style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center' }}
        >
          <Button className={classNames({ [css.mobileButtonCondensed]: isCondensedStyle })}>
            Start Selling
          </Button>
        </NamedLink>
      </Box>
    </>
  );
};

export default SellerInfoTab;
