import { Box, Typography } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';
import { useShopConfig } from '../../../hooks/shopConfig';
import { FontConfigSection } from '../../../types/shopConfig/shopConfigV2';

interface GeneralInfoTitleProps {
  children: ReactNode;
}

const GeneralInfoTitle: FC<GeneralInfoTitleProps> = (props) => {
  const { children } = props;

  const { fontConfig } = useShopConfig();
  const typographyVariant = fontConfig[FontConfigSection.AboutPageGeneralInfoTitle];

  return (
    <Box mb={1}>
      <Typography variant={typographyVariant}>{children}</Typography>
    </Box>
  );
};

export default GeneralInfoTitle;
