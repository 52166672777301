import { Box, Typography } from '@material-ui/core';
import React, { FC, ReactNode } from 'react';

interface BuyerSellerInfoTitleProps {
  children: ReactNode;
}

const BuyerSellerInfoTitle: FC<BuyerSellerInfoTitleProps> = (props) => {
  const { children } = props;

  return (
    <Box mb={3}>
      <Typography variant="h1" style={{ textAlign: 'center' }}>
        {children}
      </Typography>
    </Box>
  );
};

export default BuyerSellerInfoTitle;
