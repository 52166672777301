import { Box } from '@material-ui/core';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';
import { useEnabledCustomerExperiences } from '../../../hooks/useEnabledCustomerExperiences';
import { usePageTitles } from '../../../hooks/usePageTitles';
import { useReturnInsurance } from '../../../hooks/useReturnInsurance';
import { SEEL_RETURN_ASSURANCE } from '../../../util/seelHelpers';
import { useShopConfig } from '../../../hooks/shopConfig';
import { findOptionsForSelectFilter } from '../../../util/search';
import Content from './Content';
import GeneralInfoTitle from './GeneralInfoTitle';
import { TypographyWrapper } from '../../../components';
import css from '../AboutPage.module.css';
import { TypographyWeight } from '../../../components/TypographyWrapper/TypographyWrapper';
import { doesShopAllowBrandDirectReturns } from '../../../util/returns';
import { formatDollarAmountAsCurrency } from '../../../util/currency';

const GeneralInfo: FC = () => {
  const { allowSell } = useEnabledCustomerExperiences();
  const { purchasesTitle } = usePageTitles();

  const explainerCopy = `${
    allowSell
      ? `We don’t disburse funds to your Seller until you’ve verified the order within ${purchasesTitle} page. `
      : ''
  }If there’s an issue with your order, file a Misrepresentation Claim within 72 hours of
  receiving the item. If more than 72 hours have passed without receiving a verification
  or Claim, we will automatically verify your order and returns will no longer be
  possible.`;

  return (
    <>
      <GeneralInfoTitle>Treet Protection</GeneralInfoTitle>
      <Content>
        <TypographyWrapper variant="body1">
          All orders made through Treet are covered by Treet Protection: get the item that was
          promised in the condition it was promised in, or receive a full refund.
        </TypographyWrapper>
        <Box mt={1}>
          <TypographyWrapper variant="body1">{explainerCopy}</TypographyWrapper>
        </Box>
      </Content>
    </>
  );
};

const BrandDirectReturnPolicy: FC = () => {
  const { returnShippingPrice } = useShopConfig();
  const intl = useIntl();

  return (
    <>
      <GeneralInfoTitle>Brand Direct Return Policy</GeneralInfoTitle>
      <Content>
        <TypographyWrapper variant="body1">
          {`All Brand Direct orders made through Treet have a 14-day return policy. Items must be in an
        unused, unaltered condition and returned with their tags and packaging. The original shipping fee will not be returned and a ${formatDollarAmountAsCurrency(
          intl,
          returnShippingPrice.price,
          returnShippingPrice.currency
        )} return shipping fee will be deducted from your final refund.`}
        </TypographyWrapper>
        <Box mt={1}>
          <TypographyWrapper variant="body1">
            Please note that this 14-day return policy applies only to Brand Direct items. For other
            items, please refer to the "Treet Protection" information below.
          </TypographyWrapper>
        </Box>
      </Content>
    </>
  );
};

const ReturnProcess: FC = () => {
  const { purchasesTitle } = usePageTitles();

  return (
    <>
      <GeneralInfoTitle>Return Process</GeneralInfoTitle>
      <Content>
        <TypographyWrapper variant="body1">
          <ol>
            <li>
              <TypographyWrapper
                variant="body1"
                weight={TypographyWeight.Bold}
                typographyOverrides={{ display: 'inline' }}
              >
                Submit a Claim:
              </TypographyWrapper>{' '}
              <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                File a claim on {purchasesTitle} page within 72 hours of receiving the item.
              </TypographyWrapper>
            </li>
            <li>
              <TypographyWrapper
                variant="body1"
                weight={TypographyWeight.Bold}
                typographyOverrides={{ display: 'inline' }}
              >
                Treet Review:
              </TypographyWrapper>{' '}
              <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                Treet will review your Claim and get back to you with any additional questions or
                required photos.
              </TypographyWrapper>
            </li>
            <li>
              <TypographyWrapper
                variant="body1"
                weight={TypographyWeight.Bold}
                typographyOverrides={{ display: 'inline' }}
              >
                Print Return Label & Ship:
              </TypographyWrapper>{' '}
              <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                If your Claim is accepted, we’ll provide a return shipping label for you to print
                and return the item within 72 hours.{' '}
              </TypographyWrapper>
            </li>
            <li>
              <TypographyWrapper
                variant="body1"
                weight={TypographyWeight.Bold}
                typographyOverrides={{ display: 'inline' }}
              >
                Receive Full Refund:
              </TypographyWrapper>{' '}
              <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                Once your tracking info shows the item is in the mail, we’ll disburse your full
                refund.
              </TypographyWrapper>
            </li>
          </ol>
        </TypographyWrapper>
      </Content>
    </>
  );
};

const WhatsCovered: FC = () => (
  <>
    <GeneralInfoTitle>What’s covered?</GeneralInfoTitle>
    <Content>
      <TypographyWrapper variant="body1">
        <ul>
          <li>
            <TypographyWrapper
              variant="body1"
              weight={TypographyWeight.Bold}
              typographyOverrides={{ display: 'inline' }}
            >
              Misrepresented items:
            </TypographyWrapper>{' '}
            <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
              The item has notable quirks that weren’t mentioned in the original post.
            </TypographyWrapper>
          </li>
          <li>
            <TypographyWrapper
              variant="body1"
              weight={TypographyWeight.Bold}
              typographyOverrides={{ display: 'inline' }}
            >
              Incorrect items:
            </TypographyWrapper>{' '}
            <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
              The item from the original post is not the item that was received.
            </TypographyWrapper>
          </li>
          <li>
            <TypographyWrapper
              variant="body1"
              weight={TypographyWeight.Bold}
              typographyOverrides={{ display: 'inline' }}
            >
              Inauthentic items:
            </TypographyWrapper>{' '}
            <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
              The item is not an authentic piece from the brand.
            </TypographyWrapper>
          </li>
        </ul>
      </TypographyWrapper>
    </Content>
  </>
);

const WhatIfDislikeOrBadFit: FC = () => {
  const { shouldOfferReturnInsurance } = useReturnInsurance();
  const { purchasesTitle } = usePageTitles();
  const { filters } = useShopConfig();

  const sizeOptions = findOptionsForSelectFilter('size', filters);
  const hasSizeOptions = sizeOptions && sizeOptions.length;

  return (
    <>
      <GeneralInfoTitle>
        What if I don’t like my item{hasSizeOptions ? ' or it doesn’t fit' : ''}?
      </GeneralInfoTitle>
      <Content>
        <TypographyWrapper variant="body1">
          If you receive an item that you don’t want for whatever reason, you can re-list the item
          without paying any commission or fees. In order to qualify, you must email
          support@treet.co{' '}
          <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
            within 72 hours of receiving the item with the following information:
            {/* Need two here because the first one doesn't actually show up */}
            <br />
            <br />
            <ul>
              <li>
                <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                  Order # of the original purchase
                </TypographyWrapper>
              </li>
              <li>
                <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                  Your new listing
                </TypographyWrapper>
              </li>
              <li>
                <TypographyWrapper variant="body1" typographyOverrides={{ display: 'inline' }}>
                  Brief description of why you will be re-listing the item
                </TypographyWrapper>
              </li>
            </ul>
            <br />
            After receiving the above information, we will approve the listing and waive the
            commission fee once it’s sold.
          </TypographyWrapper>
        </TypographyWrapper>
        {shouldOfferReturnInsurance && (
          <>
            <Box mt={1}>
              <TypographyWrapper variant="body1">
                If eligible, you can also choose to add Seel {SEEL_RETURN_ASSURANCE} to your order
                at checkout. {SEEL_RETURN_ASSURANCE} gives you a 7-day return window on your
                otherwise final sale items. If you’re unhappy with the purchase for any reason, Seel
                will buy it back from you for 100% of the purchase price you paid.
              </TypographyWrapper>
            </Box>
            <Box mt={1}>
              <TypographyWrapper variant="body1">
                All purchases are subject to a return period of 7 days, and returns must be
                initiated within 7 days from the date your package is marked as delivered by the
                carrier. To initiate a return, please go to {purchasesTitle} page and follow the
                instructions for submitting a return.
              </TypographyWrapper>
            </Box>
            <Box mt={1}>
              <TypographyWrapper variant="body1">
                In the event your entire order is cancelled by the Seller or all items in your order
                are successfully disputed, the {SEEL_RETURN_ASSURANCE} fee will be refunded to you.
                However, the fee will not be refunded for partial cancellations or partial disputes.
              </TypographyWrapper>
            </Box>
            <Box mt={1}>
              <TypographyWrapper variant="body1">
                Please also note that shipping fees are not covered by {SEEL_RETURN_ASSURANCE}. The
                original shipping fee will not be returned and a $10 return shipping fee will be
                deducted from your final refund. Once the return is received and approved, the
                refund will be processed to your original form of payment.
              </TypographyWrapper>
            </Box>
            <Box mt={1}>
              <TypographyWrapper variant="body1">
                {SEEL_RETURN_ASSURANCE} is currently only offered on orders shipping to addresses in
                the United States.
              </TypographyWrapper>
            </Box>
          </>
        )}
      </Content>
    </>
  );
};

const SellerReturnPolicy: FC = () => (
  <>
    <GeneralInfoTitle>Seller Return Policy</GeneralInfoTitle>
    <Content>
      <TypographyWrapper variant="body1">
        As a Seller on Treet, we trust that you will represent your items correctly when posted. If
        for whatever reason, a Claim is raised and we need to return the item to you, Treet will
        cover the customer support and cost of return shipping.
      </TypographyWrapper>
      <Box mt={1}>
        <TypographyWrapper variant="body1">
          Of course, this isn’t an ideal scenario for you or the Buyer and we hope to avoid it as
          much as possible. In order to reduce your chances of receiving a returned item, make sure
          your post contains all relevant information and photos of the item’s condition.
        </TypographyWrapper>
      </Box>
      <Box mt={1}>
        <TypographyWrapper variant="body1">
          If you’d like to re-list your item after it’s been returned, please add any new relevant
          information to your listing and submit it for review. It will go to the top of our queue
          for us to review and post immediately.
        </TypographyWrapper>
      </Box>
    </Content>
  </>
);

const TreetProtectionTab: FC = () => {
  const { allowSell } = useEnabledCustomerExperiences();
  const { internationalConfig, returnShippingPrice } = useShopConfig();

  const shopAllowsBrandDirectReturns = doesShopAllowBrandDirectReturns(
    internationalConfig,
    returnShippingPrice
  );

  const sectionsToRender = [
    { key: 'general-info', content: <GeneralInfo /> },
    { key: 'return-process', content: <ReturnProcess /> },
    { key: 'whats-covered', content: <WhatsCovered /> },
    ...(allowSell
      ? [
          { key: 'what-if-dislike', content: <WhatIfDislikeOrBadFit /> },
          { key: 'seller-return-policy', content: <SellerReturnPolicy /> },
        ]
      : []),
  ];

  if (shopAllowsBrandDirectReturns) {
    sectionsToRender.unshift({
      key: 'brand-direct-return-policy',
      content: <BrandDirectReturnPolicy />,
    });
  }

  return (
    <>
      {sectionsToRender.map((section) => (
        <Box key={section.key} className={css.treetProtection}>
          {section.content}
        </Box>
      ))}
    </>
  );
};
export default TreetProtectionTab;
